<template>
  <div class="nav-channel">
    <van-nav-bar @click-left="$router.back()">
      <template #title>
        <span class="channel">选择频道</span>
      </template>
      <template #left>
        <span class="iconfont ico">&#xe60b; </span>
      </template>
    </van-nav-bar>
    <div class="channel-wrap">
      <div class="channel-item" @click="ChangechannelID(item.id)" v-for="item in channels" :key="item.id">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      channels: []
    }
  },
  created() {
    this.queryChannel()
  },
  methods: {
    //查询所有频道
    async queryChannel() {
      let { data: res } = await this.$api.queryChannel(0);
      if (res.code !== 200) return this.$toast.fail(res.msg);
      res.data.unshift({ id: 0, name: '推荐' })
      let temp = res.data;
      for (let i = 0;i<temp.length;i++) {
        if (temp[i].id === 10079) {
          temp.splice(i,1)
          break;
        }
      }
      this.channels = temp
    },
    ChangechannelID(id) {
      this.$store.commit('increment', id)
      this.$router.push('/')
    }
  },
}
</script>

<style lang="less">
.nav-channel {
  .van-nav-bar {
    background: #F7321C;
    .ico {
      color: #ffffff;
      font-size: 24px;
    }
  }
  .channel {
    color: #ffffff;
  }
  .channel-wrap {
    box-sizing: border-box;
    padding: 30px 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .channel-item {
      margin: 0px 5px 20px 5px;
      min-width: 76px;
      height: 33px;
      line-height: 36px;
      text-align: center;
      background: #f8f8f8;
      border-radius: 17px;
      font-size: 13px;
      color: #858f99;
    }
  }
}
</style>
